<template>
    <div class="find">
        <CommonTitle :text="text" :cont="cont"></CommonTitle>
        <div class="find-cont">
            <div class="find-tab-bar">
                <span
                    @click="tab(index)"
                    v-for="(item,index) in items"
                    :key="index"
                    :class="{findactive : index==curId}"
                >
                {{item.name}}
                </span>
            </div>
            <div class="find-tab-cont">
                <FindAccount v-if="curId === 0"></FindAccount>
                <FindPwd v-else></FindPwd>
            </div>
        </div>
    </div>
</template>

<script>
import CommonTitle from '../../components/CommonTitle'
import FindAccount from './FindAccount'
import FindPwd from './FindPwd'
export default {
    components: {
        CommonTitle,
        FindAccount,
        FindPwd
    },
    data(){
        return{
            text:this.$t('查询账号密码'),
            cont:this.$t('查询账号密码'),
            curId: 0,
            items: [
                {name: this.$t('查詢帳號')},
                {name: this.$t('查詢密碼')}
            ]
        }
    },
    mounted(){
        if (this.$route.query.tab) {
            let currentTab = parseInt(this.$route.query.tab)
            this.curId = currentTab
        }  
    },
    methods:{
        tab(index) {
            let _this = this
            _this.curId = index
        }
    },
    watch:{
      $route(){
        let currentTab = parseInt(this.$route.query.tab)
        console.log(currentTab)
        this.curId = currentTab
      },
    }
}
</script>

<style lang="scss">
.find{
    font-size: 18px;
    .find-cont{
        max-width: 800px;
        margin: 100px auto;
        min-height: 1000px;
        @media only screen and (max-width: 900px){
            width: 100%;
            margin: 50px 20px;
        }
        .find-tab-bar{
            span{
                cursor: pointer;
                font-size: 22px;
                display: inline-block;
                margin-right: 30px;
                padding-bottom: 10px;
            }
        }
        .find-tab-cont{
            margin-top:50px;
        }
        .findactive{
            color: #3E46FE;
            border-bottom: 1px solid #3E46FE;
        }
    }
    .btn-container{
        width: 80%;
        margin: 50px auto;
        display: flex;
        p{
            cursor: pointer;
            width: 45%;
            text-align: center;
            color: #fff;
            height: 50px;
            line-height: 50px;
            font-size: 22px;
            &:nth-child(1){
                background: #C5C9CE;
                margin-right: 30px;
            }
            &:nth-child(2){
                background: #2C82FF;
            }
        }
    }
}
</style>