<template>
  <div>
    <div class="pwd">
      <div class="item-name">{{ this.$t("輸入新密碼") }}:</div>
      <div class="ipt">
        <a-input-password v-model="pwd" :placeholder="this.$t('輸入新密碼')" />
        <div class="redcolor">{{ pwdErr }}</div>
      </div>
    </div>

    <div class="pwdagain">
      <div class="item-name">{{ this.$t("請確認密碼") }}:</div>
      <div class="ipt">
        <a-input-password
          v-model="pwdagain"
          :placeholder="this.$t('請確認密碼')"
        />
        <div class="redcolor">{{ pwdErrAgain }}</div>
      </div>
    </div>
    <div class="btn-container">
      <p @click="canel()">
        <span>{{ this.$t("取消") }}</span>
      </p>
      <p @click="getSure()">
        <loading v-show="isLoad"></loading>
        <span style="display: inline-block; margin-left: 10px">{{
          this.$t("確認")
        }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import request from "../../../api/request";
import validate from "../../../utils/validate";
import { encrypt } from "../../../utils/jsencrypt";
import loading from "../../../components/Loading";
export default {
  components: {
    loading,
  },
  props: {
    num: {
      type: String,
    },
    code: {
      type: String,
    },
    aaa: {
      type: String,
    },
    phoneNum: {
      type: String,
    },
  },
  data() {
    return {
      isLoad: false,
      fullWidth: 0,
      pwd: "",
      pwdagain: "",
      pwdErr: this.$t("*密码至少要包含8位英文和数字"),
      pwdErrAgain: this.$t("*密码至少要包含8位英文和数字"),
    };
  },
  mounted() {},
  methods: {
    canel() {
      this.$router.go(0);
    },
    getSure() {
      let _this = this;
      let resPwd = validate.password(_this.pwd);
      let resPwdagain = validate.password(_this.pwdagain);
      if (resPwd) {
        _this.pwdErr = _this.$t(resPwd);
        return;
      } else {
        _this.pwdErr = "";
      }
      if (resPwdagain) {
        _this.pwdErrAgain = _this.$t(resPwdagain);
        return;
      } else {
        _this.pwdErrAgain = "";
      }
      if (_this.pwd != _this.pwdagain) {
        _this.$message.warning(_this.$t("兩次輸入的密碼並不相符，請重新輸入"));
        return;
      }
      let data = {
        phone: _this.num,
        password: encrypt(_this.pwdagain),
        verify_source: 1,
        verify_type: 4,
        code: _this.code,
        username: _this.phoneNum,
        areacode: _this.aaa,
      };
      _this.isLoad = true;
      request.post("/verify/password", data).then(function (res) {
        if (res.status == 200) {
          _this.$message.success(_this.$t("修改成功"));
          _this.isLoad = false;
          _this.pwd = "";
          _this.pwdagain = "";
        } else {
          _this.$message.error(res.detail);
          _this.isLoad = false;
          _this.pwd = "";
          _this.pwdagain = "";
        }
      });
    },
  },
};
</script>

<style lang="scss">
.pwd {
  display: flex;
  margin: 30px 0;
  .item-name {
    line-height: 50px;
    width: 22%;
  }
  .ipt {
    width: 75%;
  }
  input {
    height: 50px;
    line-height: 50px;
    font-size: 20px;
  }
}
.pwdagain {
  display: flex;
  .item-name {
    line-height: 50px;
    width: 22%;
  }
  .ipt {
    width: 75%;
  }
  input {
    height: 50px;
    line-height: 50px;
    font-size: 20px;
  }
}
</style>
