<template>
    <div class="find-account">
        <div class="find-item-tab-bar">
            <span
                @click="tab(index)"
                v-for="(item,index) in items"
                :key="index"
                :class="{findactive : index==curId}"
            >
            {{item.name}}
            </span>
        </div>
        <div class="find-account-tab-cont">
            <div v-if="curId == 0" class="phone-cont">
                <ul>
                    <li>
                        <div class="item-name">{{this.$t('手機')}}:</div>
                        <div class="phone-container clearfix">
                            <div class="num-container">
                                <div class="top">
                                <select v-model="aaa" name="num">
                                    <option value="82">KR+82</option>
                                </select>
                                <div class="phone-box">
                                    <a-input 
                                    v-model="userPhone"
                                    placeholder="01XXXXXXXXX"
                                    type="text" 
                                    />
                                </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="item-name">
                            {{this.$t('帳號')}}：
                        </div>
                        <div class="num">{{phoneName}}</div>
                    </li>
                </ul>
                <!-- btn -->
                <div class="btn-container">
                    <p @click="canel()"><span>{{this.$t('取消')}}</span></p>
                    <p @click="clickPhone()">
                        <loading v-show="isLoad"></loading>
                        <span style="display: inline-block; margin-left: 10px">{{
                        this.$t("確認")
                        }}</span>
                    </p>
                </div>
            </div>
            <div v-else class="email-cont">
                <ul>
                    <li>
                        <div class="item-name">{{this.$t('信箱')}}:</div>
                        <div class="email-container clearfix">
                            <a-input
                            v-model="userEmail"
                            placeholder="01XXXXXXXXX"
                            type="text" 
                            />
                        </div>
                    </li>
                    <li>
                        <div class="item-name">
                            {{this.$t('帳號')}}：
                        </div>
                        <div class="num">{{emailName}}</div>
                    </li>
                </ul>
                <!-- btn -->
                <div class="btn-container">
                    <p @click="canel()"><span>{{this.$t('取消')}}</span></p>
                    <p @click="clickEmail()">
                        <loading v-show="isLoad"></loading>
                        <span style="display: inline-block; margin-left: 10px">{{
                        this.$t("確認")
                        }}</span>
                    </p>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import loading from '../../components/Loading'
import request from "../../api/request";
import validate from "../../utils/validate";
export default {
    components: {
      loading
    },
    data(){
        return{
            curId: 0,
            items:[
                {name:this.$t('手機')},
                {name:this.$t('信箱')},
            ],
            aaa: "82",
            fullWidth: 0,
            isLoad:false,
            userPhone:'',
            userEmail:'',
            phoneName:'-',
            emailName:'-',
        }
    },
    methods:{
        handleResize () {
            this.fullWidth = document.documentElement.clientWidth;
        },
        tab(index) {
            this.curId = index;
        },
        clickPhone(){
            let _this = this
            _this.isLoad = true
            request.get('/users', {
            phone:_this.userPhone,areacode:_this.aaa
            }).then(function (res) {
                if (res.status == 200) {
                    _this.isLoad = false
                    let data = res.data.results
                    if(res.data.results.length>0){
                        var username = "";
                        for (var i = 0; i < data.length; i++) {
                            username += data[i].username+ ",";
                        }
                        _this.phoneName = username
                    }else{
                        _this.$message.warning(_this.$t('暫無數據'));
                    }
                }else{
                    _this.$message.error(res.detail);
                    _this.isLoad = false
                }
            })
        },
        clickEmail(){
            let _this = this
            let res = validate.email(_this.userEmail)
            if(res){
                _this.$message.error(res);
                return
            }else{
                _this.isLoad = true
                request.get('/users', {
                    email:_this.userEmail
                    }).then(function (res) {
                    if (res.status == 200) {
                        _this.isLoad = false
                        let data = res.data.results
                        if(res.data.results.length>0){
                            var username = "";
                            for (var i = 0; i < data.length; i++) {
                                username += data[i].username+ ",";
                            }
                            _this.emailName= username
                        }else{
                            _this.$message.warning(_this.$t('暫無數據'));
                        }
                    }else{
                        _this.$message.error(res.detail);
                        _this.isLoad = false
                    }
                })
            }
        },
        canel(){
            this.$router.go(0)
        }
    },
    mounted() {
        this.handleResize()
    },
    created(){
        window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.handleResize)
    },
}
</script>

<style lang="scss">
.find-account{
    .find-item-tab-bar{
        span{
            color: #909499;
            font-size: 20px;
            cursor: pointer;
            display: inline-block;
            margin-right: 20px;
            text-align: center;
            font-weight: bold;
            padding: 2px 0;
            width: 100px;
            height: 40px;
            line-height: 40px;
        }
    }
    .find-account-tab-cont{
        margin-top: 30px;
        .phone-cont{
            ul{
                li{
                    display: flex;
                    margin-bottom: 30px;
                    .phone-container {
                        width: 80%;
                        .num-container {
                            float: left;
                            margin-right: 20px;
                            width: 100%;
                            @media only screen and (max-width: 900px) {
                                width: 100%;
                            }
                            .top {
                                display: flex;
                                    select {
                                        width: 20%;
                                        margin-right: 20px;
                                        font-weight: 300;
                                        color: #999;
                                        border: 1px solid #d9d9d9;
                                        background: transparent;
                                        height: 50px;
                                        padding: 0 10px;
                                        option {
                                            border: none;
                                            background: transparent;
                                        }
                                    }
                                .phone-box{
                                    width: 80%;
                                        input {
                                        width: 100%;
                                        height: 50px;
                                        line-height: 50px;
                                        color: #222;
                                        outline: none;
                                    }
                                    button {
                                        width: 45%;
                                        height: 30px;
                                        line-height: 30px;
                                        font-size: 18px;
                                        background-color: #c6a86c;
                                        border-radius: 40px;
                                    }
                                }
                            }
                        }
                        .code-container {
                            float: left;
                            width: 20%;
                            input {
                                height: 50px;
                                line-height: 50px;
                                color: #222;
                                border: 1px solid #d9d9d9;
                            }
                        }
                    }
                }
            }
        }
        .item-name{
            line-height: 50px; 
            width: 20%;
        }
        .num{
            height: 50px;
            line-height: 50px;
        }
        .email-cont{
            ul{
                li{
                   display: flex;
                   margin-bottom: 30px;
                    .item-name{
                        line-height: 50px; 
                        width: 20%;
                    } 
                    .email-container{
                        width: 80%;
                        input {
                            width: 100%;
                            height: 50px;
                            line-height: 50px;
                            color: #222;
                            outline: none;
                        }
                    }
                }
            }
        }
    }
    .findactive{
        color: #fff !important;
        background: #3E46FE;
    }
}
</style>