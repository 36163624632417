<template>
    <div class="find-pwd">
        <div v-show="!phonePwd && !emailPwd">
            <div class="find-item-tab-bar">
            <span
                @click="tab(index)"
                v-for="(item,index) in items"
                :key="index"
                :class="{findactive : index==curId}"
            >
            {{item.name}}
            </span>
            </div>
            <div class="find-pwd-tab-cont">
                <div v-if="curId == 0">
                    <div class="phone-pwd">
                        <ul>
                            <li>
                                <div class="item-name">{{this.$t('帳號：')}}</div>
                                <div class="ipt-box"><a-input v-model="phoneNum" :placeholder="this.$t('請輸入帳號')" /></div>
                            </li>
                            <li>
                                <div class="item-name">{{this.$t('手機')}}:</div>
                                <div class="phone-container clearfix">
                                    <div class="num-container">
                                        <div class="top">
                                        <select v-model="aaa" name="num">
                                            <option value="82">KR+82</option>
                                        </select>
                                        <div class="phone-box">
                                            <div class="phone-box-ipt">
                                                <a-input 
                                                v-model="phone"
                                                placeholder="01XXXXXXXXX"
                                                type="text" 
                                                />
                                                <button v-show="countshow" @click="getCodePhone()">
                                                <span v-if="fullWidth > 900">{{ this.$t("獲取驗證碼") }}</span>
                                                <span v-else>{{ this.$t("驗證碼") }}</span>
                                                </button>
                                                <button style="background: #d9d9d9; color: #333" v-show="!countshow">
                                                <span class="count">{{ count }} s</span>
                                                </button>
                                            </div>
                                            <div class="redcolor">{{phoneerr}}</div>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="code-container">
                                        <div>
                                        <a-input 
                                            v-model="phoneCode"
                                            :placeholder="this.$t('請輸入驗證碼')"
                                            type="text"
                                        />
                                        <div class="redcolor">{{phonecodeerr}}</div>
                                        </div>
                                    </div>
                                </div>  
                            </li>
                            <li>
                                <div class="btn-cont"> 
                                    <p class="sureBtn" @click="cxPhone()">
                                        <loading v-show="isLoad"></loading>
                                        <span style="display: inline-block; margin-left: 10px">{{
                                        this.$t("確認")
                                        }}</span>
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-else>
                    <div class="email-pwd">
                        <ul>
                            <li>
                                <div class="item-name">{{this.$t('帳號：')}}</div>
                                <div class="ipt-box"><a-input v-model="emailNum" :placeholder="this.$t('請輸入帳號')" /></div>
                            </li>
                            <li>
                                <div class="item-name">{{this.$t('信箱')}}:</div>
                                <div class="email-container clearfix">
                                    <div class="num-container">
                                        <div class="top">
                                        <div class="email-box">
                                            <div class="email-box-ipt">
                                                <a-input
                                                v-model="email"
                                                placeholder="01XXXXXXXXX"
                                                type="text" 
                                                />
                                                <button v-show="countshow1" @click="getCodeEmail()">
                                                <span v-if="fullWidth > 900">{{ this.$t("獲取驗證碼") }}</span>
                                                <span v-else>{{ this.$t("驗證碼") }}</span>
                                                </button>
                                                <button style="background: #d9d9d9; color: #333" v-show="!countshow1">
                                                <span class="count">{{ count1 }} s</span>
                                                </button>
                                            </div>
                                            <div class="redcolor">{{emailerr}}</div>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="code-container">
                                        <div>
                                        <a-input 
                                            v-model="emailCode"
                                            :placeholder="this.$t('請輸入驗證碼')"
                                            type="text"
                                        />
                                        <div class="redcolor">{{emailcodeerr}}</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="btn-cont"> 
                                    <p class="sureBtn" @click="cxEmail()">
                                        <loading v-show="isLoad"></loading>
                                        <span style="display: inline-block; margin-left: 10px">{{
                                        this.$t("確認")
                                        }}</span>
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <Phone :code="phoneCode" :num="phone" :phoneNum="phoneNum" :aaa="aaa" v-show="phonePwd"></Phone>
        <Email :code="emailCode" :num="email" :emailNum="emailNum" v-show="emailPwd"></Email>
    </div>
</template>

<script>
import loading from '../../components/Loading'
import validate from "../../utils/validate";
import request from "../../api/request";
import Email from './Pwd/Email'
import Phone from './Pwd/Phone'
export default {
    components: {
      loading,
      Phone,
      Email
    },
    data(){
        return{
            phonePwd:false,
            emailPwd:false,
            curId: 0,
            items:[
                {name:this.$t('手機')},
                {name:this.$t('信箱')},
            ],
            aaa: "82",
            fullWidth: 0,
            isLoad:false,
            phone:'',
            phoneNum:'',
            phoneCode:'',
            countshow: true,
            count: '',
            timer: null,
            email:'',
            emailNum:'',
            emailCode:'',
            countshow1: true,
            count1: '',
            timer1: null,
            emailerr:'',
            phoneerr:'',
            phonecodeerr:'',
            emailcodeerr:''
        }
    },
    methods:{
        handleResize () {
            this.fullWidth = document.documentElement.clientWidth;
        },
        tab(index) {
            this.curId = index;
        },
        cxPhone(){
            let _this = this
            let resphone = validate.code(_this.phoneCode)
            if(resphone){
                _this.phonecodeerr =_this.$t(resphone)
                return
            }else{
                _this.phonecodeerr = ''
                 _this.isLoad = true
                let data = {
                    phone:_this.phone,
                    areacode:_this.aaa,
                    verify_source:1,
                    verify_type:4,
                    code:_this.phoneCode
                }
                request.post('/verify/check', data).then(function (res) {
                    if (res.status == 200) {
                        _this.isLoad = false
                        _this.phonePwd = !_this.phonePwd
                    }else{
                        _this.$message.error(res.detail);
                    }
                })
            }
        },
        cxEmail(){
            let _this = this
            let resemail = validate.code(_this.emailCode)
            if(resemail){
                _this.emailcodeerr =_this.$t(resemail)
                return
            }else{
                _this.emailcodeerr = ''
                _this.isLoad = true
                let data = {
                    email:_this.email,
                    verify_source:2,
                    verify_type:4,
                    code:_this.emailCode
                }
                request.post('/verify/check', data).then(function (res) {
                    if (res.status == 200) {
                        _this.isLoad = false
                        _this.emailPwd = !_this.emailPwd
                    }else{
                        _this.$message.error(res.detail);
                    }
                })
            }
            
        },
        getCodePhone(){
            let _this = this
            let res = validate.phone(_this.phone)
            if(res){
                _this.phoneerr =_this.$t(res)
                return
            }else{
                _this.phoneerr = ''
                const TIME_COUNT = 60;
                if (!_this.timer) {
                    _this.count = TIME_COUNT;
                    _this.countshow = false;
                    _this.timer = setInterval(() => {
                        if (_this.count > 0 && _this.count <= TIME_COUNT) {
                            _this.count--;
                        } else {
                            _this.countshow = true;
                            clearInterval(_this.timer);
                            _this.timer = null;
                        }
                    }, 1000)
                }
                let data = {
                    phone:_this.phone,
                    areacode:_this.aaa,
                    verify_source:1,
                    verify_type:4
                }
                request.post('/verify/send', data).then(function (res) {
                    if (res.status == 200) {
                        _this.$message.success(_this.$t("發送成功"));
                    }
                })
            }
            
        },
        getCodeEmail(){
            let _this = this
            let res = validate.email(_this.email)
            if(res){
                _this.emailerr =_this.$t(res)
                return
            }else{
                 _this.emailerr = ''
                const TIME_COUNT = 60;
                if (!_this.timer1) {
                    _this.count1 = TIME_COUNT;
                    _this.countshow1 = false;
                    _this.timer1 = setInterval(() => {
                        if (_this.count1 > 0 && _this.count1 <= TIME_COUNT) {
                            _this.count1--;
                        } else {
                            _this.countshow1 = true;
                            clearInterval(_this.timer1);
                            _this.timer1 = null;
                        }
                    }, 1000)
                }
                let data = {
                    email:_this.email,
                    verify_source:2,
                    verify_type:4
                }
                request.post('/verify/send', data).then(function (res) {
                    if (res.status == 200) {
                        _this.$message.success(_this.$t("發送成功"));
                    }
                })
            }
            
        }
    },
    mounted() {
        this.handleResize()
    },
    created(){
        window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.handleResize)
    },
}
</script>

<style lang="scss">
.find-pwd{
    .find-item-tab-bar{
        span{
            font-size: 20px;
            color: #909499;
            cursor: pointer;
            display: inline-block;
            margin-right: 20px;
            text-align: center;
            font-weight: bold;
            padding: 2px 0;
            width: 100px;
            height: 40px;
            line-height: 40px;
        }
    }
    .find-pwd-tab-cont{
        margin-top: 30px;
        .item-name{
            line-height: 50px; 
            width: 20%;
        }
        input{
            height: 50px;
            line-height: 50px;
        }
        .ipt-box{
            width: 100%;
        }
        ul{
            li{
                display: flex;
                margin-bottom: 30px;
                .phone-container {
                    width: 100%;
                    .num-container {
                        float: left;
                        margin-right: 20px;
                        width: calc(80% - 20px);
                        @media only screen and (max-width: 900px) {
                            width: 100%;
                        }
                        .top {
                            display: flex;
                                select {
                                    width: 25%;
                                    margin-right: 20px;
                                    font-weight: 300;
                                    color: #999;
                                    border: 1px solid #d9d9d9;
                                    background: transparent;
                                    height: 50px;
                                    padding: 0 10px;
                                    option {
                                        border: none;
                                        background: transparent;
                                    }
                                }
                            .phone-box {
                                display: inline-block;
                                width: calc(70% - 20px);
                                .phone-box-ipt{
                                    border: 1px solid #d9d9d9;
                                }
                            input {
                                width: 55%;
                                height: 50px;
                                line-height: 50px;
                                color: #222;
                                border: none;
                                outline: none;
                                background: transparent;
                            }
                            button {
                                    width: 45%;
                                    height: 30px;
                                    line-height: 30px;
                                    font-size: 18px;
                                    background-color: #c6a86c;
                                    border-radius: 40px;
                                }
                            }
                        }
                    }
                    .code-container {
                        float: left;
                        width: 20%;
                        input {
                            height: 50px;
                            line-height: 50px;
                            color: #222;
                            border: 1px solid #d9d9d9;
                        }
                    }
                }
                .email-container{
                    width: 100%;
                    .num-container {
                        float: left;
                        margin-right: 20px;
                        width: calc(80% - 20px);
                        @media only screen and (max-width: 900px) {
                            width: 100%;
                        }
                        .top {
                            display: flex;
                            select {
                                width: 30%;
                                margin-right: 20px;
                                font-weight: 300;
                                color: #999;
                                border: 1px solid #d9d9d9;
                                background: transparent;
                                height: 50px;
                                padding: 0 10px;
                            option {
                                    border: none;
                                    background: transparent;
                                }
                            }
                            .email-box {
                                display: inline-block;
                                width: calc(100% - 20px);
                                .email-box-ipt{
                                    border: 1px solid #d9d9d9;
                                }
                                input {
                                    width: 60%;
                                    height: 50px;
                                    line-height: 50px;
                                    color: #222;
                                    border: none;
                                    outline: none;
                                    background: transparent;
                                }
                                button {
                                        width: 40%;
                                        height: 30px;
                                        line-height: 30px;
                                        font-size: 18px;
                                        background-color: #c6a86c;
                                        border-radius: 40px;
                                    }
                                }
                            }
                        }
                    .code-container {
                        float: left;
                        width: 20%;
                        input {
                            height: 50px;
                            line-height: 50px;
                            color: #222;
                            border: 1px solid #d9d9d9;
                        }
                    }
                }
            }
        }
        .btn-cont{
            width: 80%;
            margin: 50px auto;
            text-align: center;
            p{
                cursor: pointer;
                width: 45%;
                margin: 0 auto;
                text-align: center;
                color: #fff;
                height: 50px;
                line-height: 50px;
                font-size: 22px;
                background: #2C82FF;
            }
        }
    }
    .findactive{
        color: #fff !important;
        background: #3E46FE;
    }
}
</style>